import React, { useEffect, useState } from "react"
import { Children } from "react"
import "./Stories.scss"
import { CSSTransition } from "react-transition-group"
/**
 * Stories component.
 *
 * @param {number} duration - duration of one story in millisecond, eg. 1500
 * @param {JSX.Element(s)} children - stories represented with JSX element(s), eg. one element is one div with content inside of it
 * @param {boolean} transparent - show content without black background
 *
 * @returns Stories component
 */
const Stories = ({ duration, children, transparent }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [timeLeft, setTimeLeft] = useState(duration)
  const [isPaused, setIsPaused] = useState(false)

  const numberOfStories = children ? (children.length ? children.length : 1) : 0

  useEffect(() => {
    let changeTimeLeftInterval = null

    if (numberOfStories > 1) {
      changeTimeLeftInterval = setInterval(() => {
        if (!isPaused) {
          setTimeLeft(previousTimeLeft => {
            if (previousTimeLeft === 0) {
              setCurrentIndex(previousCurrentIndex => {
                const isElementExists =
                  previousCurrentIndex < numberOfStories - 1
                return isElementExists ? previousCurrentIndex + 1 : 0
              })

              return duration
            }
            return previousTimeLeft - 100
          })
        }
      }, 100)

      if (!isPaused) {
        return () => {
          clearInterval(changeTimeLeftInterval)
        }
      }
    }
  }, [timeLeft, isPaused, numberOfStories, duration])

  const animationTimeout = 400
  const defaultStyle = {
    transition: `opacity ${animationTimeout}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: {
      opacity: 0.5,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 999,
    },
    entered: {
      opacity: 1,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 999,
    },
    exiting: { opacity: 0.5, position: "relative" },
    exited: { opacity: 0.3, position: "relative" },
  }

  const updateChildrenWithProps = () =>
    Children.map(children, (child, index) => (
      <CSSTransition
        in={index === currentIndex}
        out={index !== currentIndex}
        timeout={animationTimeout}
      >
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {React.cloneElement(
              child,
              index !== currentIndex ? { style: { opacity: 0 } } : {}
            )}
          </div>
        )}
      </CSSTransition>
    ))

  const handleOnMouseOver = () => {
    setIsPaused(true)
  }

  const handleOnMouseLeave = () => {
    setIsPaused(false)
  }

  const handleDoubleClick = () => {
    if (typeof window !== "undefined" && window.innerWidth < 992) {
      setIsPaused(false)
    }
  }

  return (
    <div
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      onFocus={handleOnMouseOver}
      onDoubleClick={handleDoubleClick}
      role="button"
      tabIndex={0}
      className={`stories-container ${
        transparent ? "stories-container--transparent" : ""
      } ${
        numberOfStories === 1 ? "stories-container--without-progress-bar" : ""
      }`}
    >
      <div
        className="stories-container__progress-bars-container"
        // style={
        //   numberOfStories > 1 && isPaused
        //     ? { opacity: 0, transition: "700ms opacity" }
        //     : {}
        // }
      >
        {[...Array(numberOfStories)].map((_, index) => {
          const transition = `${
            index === currentIndex ? `${100}ms width linear` : undefined
          }`

          const progressStyles = {
            width: `${
              index < currentIndex
                ? `100%`
                : `${
                    index === currentIndex
                      ? `${100 - Math.ceil((timeLeft / duration) * 100)}%`
                      : `0%`
                  }`
            }`,
            WebkitTransition: transition,
            OTransition: transition,
            MozTransition: transition,
            transition: transition,
          }

          return (
            <div
              key={`child-${index}`}
              className="progress-bars-container__percentage_container"
              style={numberOfStories === 1 ? { display: "none" } : {}}
            >
              <div
                className="percentage_container__progress"
                style={progressStyles}
              ></div>
            </div>
          )
        })}
      </div>

      <div className="stories-container__content" style={{ height: "100%" }}>
        {numberOfStories === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            NO DATA
          </div>
        ) : numberOfStories > 1 ? (
          updateChildrenWithProps()
        ) : (
          children
        )}
      </div>
    </div>
  )
}

export default Stories
