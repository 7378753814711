import React, { useContext } from "react"
import { Accordion, Card } from "react-bootstrap"
import parse from "html-react-parser"
import { LanguageContext } from "../../contexts/languageContext"
import "./JobsAccordion.scss"
import { getLocalizedField } from "../../utils/getLocalizedField"
import { translateText } from "../../utils/translateText"
import { Link } from "gatsby"
import { LANGUAGES } from "../../constants"

const API_URL = process.env.GATSBY_API_URL

/*
@param image - String, eg. "static/images/example-image.png"
@param title - String, eg. "Example title"
@param subtitle - String, eg. "Example subtitle"
@param location - String, eg. "Example text"
@param text - String, eg. "Example text"
*/
const JobsAccordion = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]
  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de";

  return (
    <div className="jobs-accordion-container">
      <Accordion>
        {data.map(job => {
          const { id, name, body, client, city, country , body_intro ,path } = job.node[currentLanguage.label.toLowerCase()]

          const jobName = name[getLocalizedField("name", currentLanguage.label)]
          const clientName =
            client[getLocalizedField("name", currentLanguage.label)]
          const cityName =
            city[getLocalizedField("name", currentLanguage.label)]
          const countryName =
            country[getLocalizedField("name", currentLanguage.label)]

          return (
            <Card key={id}>
              <Accordion.Toggle as={Card.Header} eventKey={id}>
                <div className="job-header">
                  <img
                    className="job-logo"
                    src={`${client.staticLogo.publicUrl}`}
                    alt="Job icon"
                    width="70"
                    height="auto"
                  />
                  <div className="header">
                    <h2 className="title">{jobName}</h2>
                    <p className="subtitle">{clientName}</p>
                  </div>
                </div>
                <div className="location">
                  <p>{`${cityName}, ${countryName}`}</p>
                  {path && (
                    <>
                       <Link
                         className="link-more"
                         to={`${routePrefix}/job-listing${path}`}
                         >
                           {`${translateText(
                                "Job page",
                                currentLanguage.fullTitle,
                                googleSheetData
                              )} →`}
                         </Link>
                      </>
                   )}
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={id}>
                <Card.Body>
                  <div className="content">
                    <div className="body-company">
                     {body_intro && parse( body_intro)}
                    </div>
                  {body && parse(body)}</div>
                  <div className="btn-wrapper">
                  <a
                    className="btn btn-outline-danger btn-mail"
                    href={`mailto:${"hello@dzemo.com"}`}
                  >
                    {translateText(
                      "Apply",
                      currentLanguage.fullTitle,
                      googleSheetData
                    )}
                  </a>

                  {/* {path && (
                    <>
                       <Link
                         className="btn btn-outline-danger btn-more"
                         to={`${routePrefix}/job-listing${path}`}
                         >
                           {`${translateText(
                                "See more",
                                currentLanguage.fullTitle,
                                googleSheetData
                              )} →`}
                         </Link>
                      </>
                   )} */}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    </div>
  )
}

export default JobsAccordion
