import React from "react"
import Stories from "../Stories/Stories"
import Wave from "../Wave/Wave"
import "./TopComponent.scss"
import parse from "html-react-parser"
//const API_URL = process.env.GATSBY_API_URL

const DURATION_OF_ONE_STORY = 4000

/* A custom top component.
@param heading - Object, eg. {parts: [{text: 'Something...', highlight?: true}, {...}, ...]'
@param body - text, eg. "Something happened...."
@param children - JSX Object, eg. <Story /> or <div>Something<div> or ...
*/
const TopComponent = ({ data, imageFormatName, wave }) => {
  return (
    <>
      <div className="top-component">
        <Stories transparent duration={DURATION_OF_ONE_STORY}>
          {data.map((item, index) => {
            const { name, body, image } = item
            const { formats, alternativeText, hash, staticImages } = image[0]

            return (
              <div
                key={`${hash}_${index}`}
                className="stories-container__content-wrapper"
              >
                <div>
                  <h1 className="top-component-heading">
                    <>{parse(name)}</>
                  </h1>
                  <p className="top-component-text">{body}</p>
                </div>

                <div key={hash} className="top-component-media">
                  <picture>
                    {/* use the code below if we need the _sm format for top slider - homepage  */}
                    {/* <source
                      media="(max-width: 767px)"
                      srcSet={`${API_URL}${
                        formats[`${imageFormatName}_sm`][0].url
                      }`}
                    /> */}
                      <source
                      media="(max-width: 767px)"
                      srcSet={`${
                        staticImages[`${imageFormatName}`][1].src
                      }`}
                    />
                    <img
                      src={`${staticImages[imageFormatName][1].src}`}
                      className="story-image"
                      alt={alternativeText}
                      maxwidth={`${formats[imageFormatName][1].width}px`}
                      maxheight={`${formats[imageFormatName][1].height}px`}
                    />
                    {wave && <Wave />}
                  </picture>
                </div>
              </div>
            )
          })}
        </Stories>
      </div>
    </>
  )
}

export default TopComponent
