import React, { useMemo } from "react"
import { Layout } from "../../layouts"
import "./JobListing.scss"
import TopComponent from "../../components/TopComponent/TopComponent"
import JobsAccordion from "../../components/JobAccordion/JobsAccordion"
import Contact from "../../components/Contact/Contact"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import SEO from "../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const JobListing = ({ pageContext: { pageData, lang, companies, jobs }, data, location }) => {
  const { allDzemoApiJobs } = useMemo(
    () => localizeDataNodes(data, lang),
    [data, lang]
  )

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="job-listing-page">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        <div className="gif-content">
          <img src={gifImage} alt="gif" width="414" height="81" />
        </div>

        <section className="list-container">
          <JobsAccordion data={jobs} />
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export default JobListing

export const query = graphql`
  query jobListingPageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiJobs(sort: { fields: en___created_at, order: DESC }) {
      nodes {
        en {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
          body_intro
          path
        }
        de {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
          body_intro
          path
        }
      }
    }
  }
`
